import './SearchPage.scss';
import BusinessList from "../Components/BusinessList";
import BusinessDetails from "../Components/BusinessDetails";
import {useState} from "react";

const SearchPage = ({searchBar, closeSuggestions}) => {
    const [selectedBusiness, setSelectedBusiness] = useState()
    const handleSelectedData = (data) => {
        setSelectedBusiness(data)
    }

    return (
        <div className="search-page" >
            <div className={"list"}>
                <div className={'list-header headers'}><span>Business List</span></div>
                <BusinessList callback={handleSelectedData} selectedBusiness={selectedBusiness} searchBar={searchBar} closeSuggestions={closeSuggestions}></BusinessList>
            </div>
            <div className={"details"}>
                <div className={'details-header headers'}><span>Business Details</span></div>
                <div className={'business-details-results'}>
                    {selectedBusiness ? <BusinessDetails business={selectedBusiness}></BusinessDetails> : <div className={'no-business'}><i>Select a business to see details</i></div>}
                </div>
            </div>
        </div>
    );
}

export default SearchPage;
