import './BusinessList.scss'
import { useEffect, useState, useContext } from "react";
import SearchResult from './SearchResult/SearchResult';
import { DataContext } from '../lambda/LambdaFunctions';
import search from '../Assets/Images/search.svg';


const BusinessList = (props) => {
    const dataContext = useContext(DataContext);

    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([])
    const [categories, setCategories] = useState([])

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        if (dataContext.dataLoaded) {
            const stuff = dataContext.data.filter((business) => {
                if (business['Company Name'].toLowerCase().includes(searchInput.toLowerCase())) {
                    return true
                }
                if (business.DBA && business.DBA.toLowerCase().includes(searchInput.toLowerCase())) {
                    return true
                }
                if (business["UNSPSC Description"] && business["UNSPSC Description"].filter((desc) => {
                    return desc.toLowerCase().includes(searchInput.toLowerCase())
                }).length > 0) {
                    return true
                }
                return false
            })
            setFilteredData(stuff)
        }
    }, [searchInput, dataContext.data, dataContext.dataLoaded]);

    useEffect(() => {
        let categories = []
        filteredData.forEach((business) => {
            categories = [...categories, ...business["UNSPSC Description"]]
        })
        let categoryCollection = [...new Set(categories)]
        setCategories(categoryCollection)
    }, [filteredData])

    const openSuggestions = () => {
        if(props.searchBar && searchInput !== "" && categories.length > 0){
            props.searchBar.current.classList.add("show-suggestions");
        }
    }

    return (
        <div className={'business-list-container'}>
            <div className='search'>
                <h1>
                    Search
                </h1>
                <div ref={props.searchBar} onClick={e => e.stopPropagation()} onFocus={openSuggestions} className={searchInput === "" || categories.length === 0 ? 'search-bar' : 'show-suggestions search-bar'} >
                    <input list={'filtered-businesses'} type="search" onChange={handleChange} value={searchInput} placeholder={"Type to search"} />
                    <img src={search} alt="search" />
                    <ol id='filtered-businesses' className='suggestions'>
                        {categories.slice(0, 5).map((category) =>
                            <li onClick={() => {setSearchInput(category); props.closeSuggestions()}}>{category}</li>
                        )}
                    </ol>
                </div>
            </div>
            <div className={searchInput === "" ? "search-query" : "show-query search-query"}>Results for “{searchInput}”</div>
            <div className={'business-list'}>
                {
                    searchInput === "" ?
                        <div className='no-search'>Search for business name, category, or product</div>
                        :
                        filteredData.map((business) => {
                            return (
                                <SearchResult onClick={() => { props.callback(business) }} business={business} selected={business === props.selectedBusiness}> </SearchResult>
                            )
                        })
                }
            </div>


        </div>
    )
}
export default BusinessList