import './SearchResult.scss'
import veteran from '../../Assets/Images/v.svg'
import women from '../../Assets/Images/w.svg'
import minority from '../../Assets/Images/m.svg'
import arrow from '../../Assets/Images/rightArrow.svg'

const SearchResult = ({ business, onClick, selected }) => {
    return (
        <div className={selected ? "selected search-result" : "search-result"} onClick={onClick}>
            <div className='text'>
                <h2>{business["Company Name"]}</h2>
                <div className='categories'>
                    <img className={business["Application Type"].includes("IVBE") ? "show" : ""} src={veteran} alt="Veteran-owned"></img>
                    <img className={business["Application Type"].includes("WBE") ? "show" : ""} src={women} alt="Woman-owned"></img>
                    <img className={business["Ethnic Group"] !== "CAU" ? "show" : ""} src={minority} alt="Minority-owned"></img>
                </div>
            </div>
            <img src={arrow} alt="Details" />
        </div>
    )
}

export default SearchResult;
