import './BusinessDetails.scss'
import veteran from "../Assets/Images/v.svg";
import women from "../Assets/Images/w.svg";
import minority from "../Assets/Images/m.svg";

const BusinessDetails = (props) => {
    let business = props.business
    let companyName = props.business['Company Name']
    let DBA = props.business.DBA
    let categories = props.business['UNSPSC Description']
    let ethnicGroups = props.business['Ethnic Group']
    let UNSPSC = props.business.UNSPSC
    let phone = props.business.Phone.replace('/', '-')
    let address1 = `${props.business["Mailing Address 1"]}, ${props?.business['City']}, ${props?.business['State']}, ${props?.business["Zip Code"]}`
    let encodedAddress1 = encodeURIComponent(address1)
    let address2 = props.business["Mailing Address 2"] ? `${props.business["Mailing Address 2"]}, ${props?.business['City']}, ${props?.business['State']}, ${props?.business["Zip Code"]}` : ''
    let encodedAddress2 = encodeURIComponent(address2)
    let email = business['Email ID']

    const ethnicityCodes = {
        "ME": "Minority Business Enterprise",
        "WBE": "Women's Business Enterprise",
        "IVOSB": "Indiana Veteran Owned Small Business",
        "AIN": "Asian Indian",
        "APA": "Asian Pacific",
        "AFA": "African American",
        "CAU": "Caucasian",
        "HIS": "Hispanic",
        "MRA": "Multi-racial",
        "NAM": "Native American",
        "OTH": "Other"
    };
    return (
        <div className={'business-details-container'}>
            <div className={'company-name-container'}>
                <div>
                    <div className={'company-name'}>{companyName}</div>
                    <div className={'dba'}>{DBA ? `DBA: ${DBA}` : ''}</div>
                </div>
                <div className={'icons'}>
                    {business["Application Type"].includes("IVBE") ?
                        <div className={'icon-container'}>
                            <img className={"show"} src={veteran} alt="Veteran-owned"></img>
                            Veteran
                        </div> : ''}
                    {business["Application Type"].includes("WBE") ?
                            <div className={'icon-container'}>
                                <img className="show" src={women} alt="Woman-owned"></img>
                                Women
                            </div> : ""}
                    {business["Ethnic Group"] !== "CAU" ?
                        <div className={'icon-container'}>
                            <img className={'show'} src={minority} alt="Minority-owned"></img> Minority
                        </div> : ''}

                </div>
            </div>
            <div className={'information-container'}>
                <div className={'business-container'}>
                    {categories.length > 1 ? <h2>Business Categories</h2> : <h2>Business Category</h2>}
                    <div className={'business-categories'}>
                        {categories.map((category, i) => {
                            return (
                                <div className={'category border-bottom'}>
                                    <p>{'#' + UNSPSC[i]}</p>
                                    {category}
                                </div>)
                        })}
                    </div>
                </div>

                <div className={'contact-container'}>
                    <h2>Contact Information</h2>
                    <div className={'contact-information'}>
                        <label htmlFor={"name"}>Name</label>
                        <p id={"name"}>
                            {business['First Name'] + " "}
                            {business['LastName'] ? business['LastName'] : ''}
                        </p>
                        <label htmlFor={"email"}> Email</label>
                        <p id={"email"}>
                            <a href={`mailto:${email}`}> {email}</a>
                        </p>
                        <label htmlFor={"phone"}> Phone</label>
                        <p id={"phone"}>
                            <a href={`tel:${phone}`}>  {phone} </a>
                        </p>
                        <label htmlFor={"address"}>Address</label>
                        <p id={"address"}>
                            {address1 ?
                                <a href={`https://www.google.com/maps/search/${encodedAddress1}`}
                                   target={'-blank'}> {address1}</a> :
                                "NA"}
                        </p>
                        <p id={"address"}> {address2 ?
                            <a href={`https://www.google.com/maps/search/${encodedAddress2}`}
                               target={'-blank'}> {address2}</a> :
                            ""}</p>
                    </div>
                    <div className={'ethnic-groups'}>
                        {ethnicGroups ? <>
                                <h2>Ethnic Groups:</h2>
                                <div
                                    className={'group-names border-top border-bottom'}>{ethnicGroups}: {ethnicityCodes[ethnicGroups]}</div>
                            </>
                            : null}
                    </div>
                </div>
            </div>

        </div>

    )

}
export default BusinessDetails