import React, { useState, createContext, useEffect } from "react";

export const DataContext = createContext({});

const DataContextProvider = ({ children }) => {
    const [data, setData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch('https://public-project-user-uploads.s3.us-east-2.amazonaws.com/diverse-purchasing/businesses.json');
            const jsonData = await response.json();
            setData(jsonData);
            setDataLoaded(true);
        }
        fetchData()
    }, [])

return (
    <DataContext.Provider value={{ data: data, dataLoaded: dataLoaded }}>
        {children}
    </DataContext.Provider>
);
};

export default DataContextProvider;
