import SearchPage from "./Pages/SearchPage";
import './App.css';
import DataContextProvider from "./lambda/LambdaFunctions";
import { useRef } from "react";
import {BSUHeader} from './Components/BSUHeader.js'

function App() {
  const searchBar = useRef(null);
  const closeSuggestions = () => {
    if(searchBar){
        searchBar.current.classList.remove("show-suggestions")
    }
}

  return (
    <DataContextProvider>
      <div className="App" onClick={closeSuggestions}>
          <BSUHeader></BSUHeader>
        <SearchPage searchBar={searchBar} closeSuggestions={closeSuggestions}/>
      </div>
    </DataContextProvider>
  );
}

export default App;
